import React, { useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { useUxHandler } from '../context/uxHandlerContext';

const useStyles = makeStyles((theme) => ({
  alertFont: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MessageAlert = () => {
  const classes = useStyles();
  const { alert, setAlert } = useUxHandler();

  const handleClose = () => {
    setAlert({ ...alert, isActive: false });
  };

  return (
    <>
      <Dialog
        open={alert.isActive}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            position: 'fixed',
            top: '62px',
            right: '-26px',
            width: '350px',
            height: '200px',
            background: 'white',
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div style={{position: "absolute", width: "100%", height: "200px"}}>
          <Alert 
            severity={alert.type}
            style={{height: "100%", padding: "50px"}}
          >
            <AlertTitle
              className={classes.alertFont}
            >{alert.title}</AlertTitle>
            <span className="h4">{alert.message}</span>
          </Alert>
        </div>
      </Dialog>
    </>
  );
};

export default MessageAlert;
