import React, { createContext, useContext, useState, useEffect } from 'react';
import { BASE_URL } from '../request/settings';

const AccessContext = createContext();

export const AccessProvider = ({ children }) => {
  const [accessMenus, setAccessMenus] = useState([]);

  useEffect(() => {
    const updateData = async () => {
      const url = `${BASE_URL}/access_menus`
      const response = await fetch(url)
      const data = await response.json()
      setAccessMenus(data);
    };
    updateData()
  }, [])

  return (
    <AccessContext.Provider value={{ accessMenus }}>
      {children}
    </AccessContext.Provider>
  );
};

export const useMenuAccess = () => {
  return useContext(AccessContext);
};