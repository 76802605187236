import { UserProvider } from "@context/userContext";
import CommentsProvider from "@context/commentsContext";
import AppContextProvider from '@context/AppContext';
import UxHandlerProvider from '@context/uxHandlerContext';
import { AccessProvider } from '../context/MenuContext';
import { interceptor } from "@request/settings";

import MessageAlert from '../components/messageAlert';

import '../styles/bootstrap.min.css';
import '../styles/normalize.min.css';
import '../styles/globals.css';
import '../styles/fonts.css';

interceptor();

function MyApp({ Component, pageProps }) {
  
  return (
    <UserProvider>
      <CommentsProvider>
        <AccessProvider>
          <AppContextProvider>
            <UxHandlerProvider>
              <Component {...pageProps} />
              <MessageAlert />
            </UxHandlerProvider>
          </AppContextProvider>
        </AccessProvider>
      </CommentsProvider>
    </UserProvider>
  );
}

export default MyApp;
