import { createContext, useContext, useState, useEffect } from "react";
import { BASE_URL } from "@request/settings";

export const CommentsContext = createContext(null);

export default function CommentsProvider({ children }) {
  // const [pendingComments, setPendingComments] = useState(0);
  const [counts, setCounts] = useState({
    pendingComments: 0,
    offerCount: 0,
    couponCount: 0,
  });

  // fetch pending comments counter:
  const fetchPendingCounts = async () => {
    const url = `${BASE_URL}/pendings/pending_counts`;
    const response = await fetch(url);
    const data = await response.json();

    return {
      pendingComments: data.pending_comments || 0,
      offerCount: data.pending_offers || 0,
      couponCount: data.pending_coupons || 0,
    };
    // return data.pending_comments;
  };

  const updateCounter = async () => {
    // const pending = await fetchPendingCommentsCounter();
    // setPendingComments(pending || 0);
    const newCounts = await fetchPendingCounts();
    setCounts(newCounts);

  };

  useEffect(() => {
    updateCounter();

    const interval = setInterval(() => {
      updateCounter();
    }, 1000 * 60); // fetch every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CommentsContext.Provider value={{ counts, setCounts }}>{children}</CommentsContext.Provider>
  );
}

export function getPendingCounts() {
  const context = useContext(CommentsContext);
  if (!context) {
    throw new Error("no exite el provider para el componente");
  }
  return context.counts;
}

export function useUpdatePendingCounts() {
  const context = useContext(CommentsContext);
  if (!context) {
    throw new Error("no exite el provider para el componente");
  }

  return context.setCounts;
}
