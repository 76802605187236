import Axios from "axios";
import { getToken } from "./auth";

// export const BASE_URL = "http://localhost:4000/api/v1";
// export const BASE_URL = "https://ofertu-staging.herokuapp.com/api/v1";
// export const BASE_URL_2 = "https://ofertu-staging.herokuapp.com/api/ofertu";
export const BASE_URL = "https://back-ofertu.herokuapp.com/api/v1";
export const BASE_URL_2 = "https://back-ofertu.herokuapp.com/api/ofertu";

export const UI_URL = "https://www.ofertu.co";

export const axios = Axios.create({
  baseURL: BASE_URL,
  // timeout: 10000,
  params: {},
});

export const axios_2 = Axios.create({
  baseURL: BASE_URL_2,
  // timeout: 10000,
  params: {},
});

export const interceptor = () => {
  axios.interceptors.request.use(function (config) {
    const token = getToken();
    if (token) {  
      config.headers.Authorization = `bearer ${token}`
    }
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};

export const getUser = async () => {
  const response = await axios.get('/users/current');
  const { data } = response
  return data
}

export const MY_SEO = {
  title: "OFERTU",
  description:
    "OFERTU es la comunidad de descuentos donde tú compartes, eliges, votas comentas las ofertas y cupones más especiales del mercado con otros compradores para así, entre todos, encontrar las mejores oportunidades de compra en internet.",
  openGraph: {
    type: "website",
    url: "https://ofertu.co/",
    title: "OFERTU",
    description: "La comunidad de ofertas de Colombia",
  },
};

export const getDataFooter = async () => {
  const response = await axios.get(`/offers/data_fotter`);
  return response.data
};

export const BLACKLIST = ['109.106.241.222', '34.239.164.224', '44.192.102.249', '54.159.194.218', '100.27.41.112', '52.87.186.156', '54.163.32.57']
export const CAPTCHA_SITE_KEY = '6Le4g9gfAAAAAP8rHxhTVzfh_xy2hFvFdexq7ytn'
