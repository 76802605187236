import { createContext, useState, useEffect } from 'react';

// Crea el contexto
export const AppContext = createContext();

// Crea el proveedor del contexto
export default function AppContextProvider({ children }) {
  const [myState, setMyState] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMyState(window.innerWidth <= 768);
    };

    // Solo si window está definido (es decir, estamos en el cliente)
    if (typeof window !== 'undefined') {
      setMyState(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);

      // Limpia el event listener cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <AppContext.Provider value={{ myState, setMyState }}>
      {children}
    </AppContext.Provider>
  );
};
