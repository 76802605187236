import { createContext, useContext, useState, useMemo , useEffect } from 'react';

export const UxHandlerContext = createContext();

export default function UxHandlerProvider (props) {
  const [alert, setAlert] = useState({ isActive: false, title: 'Muy bien', message: 'Hola', type: 'info' });
  const [modalCoupon, setModalCoupon] = useState({ isActive: false, couponId: null });

  const value = useMemo(() => {
    return {
      alert,
      setAlert,
      modalCoupon,
      setModalCoupon,
    };
  }, [alert, modalCoupon]);

  return <UxHandlerContext.Provider value={value} {...props} />;
};

export const useUxHandler = () => {
  return useContext(UxHandlerContext);
};
